<template>
  <p-o-d-10-report></p-o-d-10-report>
</template>

<script>
import POD10Report from "@/components/ecologist/reports/POD-10/POD-10Report";
export default {
  name: "POD-10View",
  components: { POD10Report },
};
</script>

<style scoped></style>
